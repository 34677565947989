import { mapActions, mapGetters, mapMutations } from 'vuex'
import validationMixin from '@/mixins/validation'
import { debounce } from 'lodash'
import moment from 'moment'

export default {
    name    : 'user-students-cards',
    mixins  : [validationMixin],
    data () {
        return {
            valid           : false,
            page            : 1,
            limitCards      : 100,
            showRemoveDialog: false,
            deletedItem     : null,
            userTariffsLength: 0,
            payload         : {
                cardId        : ''
            },
        }
    },
    computed  : {
        ...mapGetters({
            cards           : 'userCards/cards',
            cardsLength     : 'userCards/cardsLength',
            
            isLoading       : 'userCards/isLoading',
            
            limit           : 'userCards/limit',
            skip            : 'userCards/skip',
            isLoading       : 'userCards/isLoading'
        }),
        headers () {
            return [
                {
                    text    : 'ID',
                    value   : 'id'
                },
                {
                    text    : 'Bin',
                    value   : 'bin'
                },
                {
                    text    : 'Mask',
                    value   : 'mask'
                },
                {
                    text    : 'Month',
                    value   : 'month'
                },
                {
                    text    : 'Year',
                    value   : 'year'
                },
                {
                    text    : 'Type',
                    value   : 'type'
                },
                {
                    text    : 'Provider',
                    value   : 'provider'
                },
                {
                    text    : 'Provider ID',
                    value   : 'provider_id'
                },
                {
                    text    : 'Created at',
                    value   : 'created_at'
                },
                {
                    text    : 'Is active',
                    value   : 'is_active'
                },
                {
                    text    : 'Is default',
                    value   : 'is_default'
                },
                {
                    text    : 'Is rectoken',
                    value   : 'is_rectoken'
                },
                {
                    text    : 'Is deleted',
                    value   : 'is_deleted'
                },
                {
                    text    : 'Actions',
                    value   : 'actions',
                    sortable: false
                }
            ]
        }
    },
    watch: {},
    created () {
        if (!this.cards.length) {
            this.fetchCards({
                userId  : this.$route.params.id
            })
        }
        
        if (this.cards) {
            if (this.skip !== 0) {
                this.page = Math.round(this.skip / this.limit) + 1
            }
        }
    },
    methods: {
        ...mapActions({
            fetchCards      : 'userCards/GET_USER_CARDS',
            remove          : 'userCards/DELETE'
        }),
        ...mapMutations({
            changeSkip      : 'userCards/CHANGE_SKIP'
        }),
        removeItem () {
            this.remove({
                userId  : this.$route.params.id,
                id      : this.deletedItem.id
            })
            .then(() => {
                this.showRemoveDialog = false;
                
                this.fetchCards({
                    userId  : this.$route.params.id
                })
                
                this.$toasted.success(this.$t('success_deleted'));
            })
        },
        changeDialogDelete (item = null) {
            this.showRemoveDialog   = !!item;
            this.deletedItem        = item;
        },
        changePagination (index) {
            this.changeSkip(this.limit * (index - 1));
            
            this.$vuetify.goTo(0);
            
            this.fetchCards({
                userId  : this.$route.params.id,
                limit   : 300
            });
        },
        formattedDate (date) {
            if (!date) return '';
            
            return moment(date).format('YYYY-MM-DD HH:mm:ss');
        },
        createRequestPayload () {
            const formData = new FormData();
            
            //formData.append('available_to'  , this.formattedDate(this.availableTo));
            formData.append('userId'        , this.$route.params.id);
            
            if (this.editedItem) {
                formData.append('_method'       , 'patch');
                formData.append('id'            , this.editedItem.id);
            }else{
                formData.append('card_id'       , this.payload.id);
            };
            
            return formData;
        }
    },
    destroyed () {
        //this.setUserTariffs([]);
        //this.setTariffs([]);
    }
}
